import { createClient } from '@/modules/api/createClient';
import { buildUrl } from '@/modules/api/buildUrl';

/**
 * 新レコメンドプロジェクト一覧
 */
const fetchRecommended = ({
  viewedProjectIds,
  nItems = 10,
  projectId,
  withUser,
  withReturns,
} = {}) => {
  const url = buildUrl('/recommend_user2item', {
    viewed_project_ids: viewedProjectIds,
    n_items: nItems,
    project_id: projectId,
    with_user: withUser,
    with_returns: withReturns,
  });

  const client = createClient({ baseURL: '/api' });
  return client.get(url);
};

export default {
  fetchRecommended,
};
