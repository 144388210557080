import { createApp } from 'vue';
import filters from '@/modules/filters';
import MCdn from '@/components/MCdn';
import ProjectRecommended from '@/pc/components/ProjectRecommended';

const mountId = 'vue-project-recommend';

const mountElement = document.getElementById(mountId);
const projectId = mountElement?.dataset?.projectId ?? '';
const app = createApp(ProjectRecommended, { projectId });

app.config.globalProperties.$filters = filters;
app.component('MCdn', MCdn);
app.mount(`#${mountId}`);
