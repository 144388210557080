<template>
  <div class="Title">
    <h3 class="subject"><slot /></h3>
    <title-link
      v-if="hasLink"
      :link-href="titleLinkHref"
      :link-target="titleLinkTarget"
      :click-tracking-category="clickTrackingCategory"
      :click-tracking-label="clickTrackingLabel"
    >
      {{ titleLinkText }}
    </title-link>
  </div>
</template>

<script>
import clickTrackingDefinition from '@/mixins/clickTrackingDefinition';
import titleDefinition from '@/mixins/titleDefinition';
import TitleLink from '@/pc/components/top/TitleLink';

export default {
  name: 'MTitle',
  components: {
    TitleLink,
  },
  mixins: [titleDefinition, clickTrackingDefinition],
};
</script>

<style scoped>
.Title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
}

.subject {
  font-size: 24px;
  font-weight: bold;
}
</style>
